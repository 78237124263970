<template>
  <section class="cancel-success">
    <!-- 标题 -->
    <img class="logo" :src="successImg" />
    <h1 class="title">{{ lang.google_cancella_finish }}</h1>
    <!-- 输入框 -->
    <!-- <input-normal
      class="input-t"
      @inpObj="
        (val) => {
          emailInpObj = val;
        }
      "
      :placeholder="lang.langCreateAccount_account_input_holder"
    /> -->
    <!-- 获取验证码 -->
    <el-button
      v-dbClick
      type="primary"
      @click="backHome"
      :class="['get-code-btn']"
      round
    >
      {{ lang.google_cancella_go_home }}
    </el-button>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { googleSendVerifyCode } from "@/api/alexaLogin.js";
import successImg from "@/assets/images/success.png";
// const successImg =require()
export default {
  name: "cancelSuccess",
  data() {
    return {
      emailInpObj: {
        // email 输入框
        inpValue: "",
        inputType: "text",
        placeholder: "Enter email",
        autoFocus: true,
      },
      successImg,
      account: this.$route.query.account,
      disabledFlag: true, // 按钮是否禁用
    };
  },
  computed: {
    ...mapState("alexaLogin", ["tabIndex", "languagePackage"]),
    lang() {
      return this.languagePackage[this.tabIndex];
    },
  },
  methods: {
    backHome() {
      this.$router.replace(`/${this.$commonFunc.getUrlChannelAppID()}/login?apply=logOff`);
      // this.$router.push(
      //   `/${this.$commonFunc.getUrlChannelAppID()}/verification-code${this.$commonFunc.linkUrlParams()}`
      // );
    },
  },
};
</script>

<style lang="less" scoped>
.cancel-success {
  padding: 90px 30px 0 30px;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  .logo {
    width: 60px;
    height: 60px;
    margin: 0 auto;
  }
  .title {
    margin-top: 20px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
  }

  .get-code-btn {
    margin-top: 120px;
    width: 100%;
    height: 44px;
    border: none;
  }
}
</style>
